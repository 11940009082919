import React, { useRef, useEffect } from 'react';
import T from 'prop-types';
import cx from 'classnames';
import Slider from 'react-slick';

const initConfig = {
  dots: true,
  className: 'center',
  centerMode: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '60px',
  autoplay: false,
  autoplaySpeed: 3000,
  variableWidth: true,
  infinite: false,
  accessibility: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false,
        autoplay: false,
        centerMode: true,
        centerPadding: '24px',
      },
    },
  ],
};

const Carousel3D = ({
  slides,
  config,
  className,
  focusOnLoad, // to use keyboard for nav
}) => {
  const sliderRef = useRef();

  useEffect(() => {
    if (focusOnLoad) {
      sliderRef.current.innerSlider.list.setAttribute('tabindex', 0);
      sliderRef.current.innerSlider.list.focus({ preventScroll: true });
    }
  }, []);

  return (
    <div className={cx('hms-carousel3d', className)}>
      <Slider
        {...{ ...initConfig, ...config }}
        ref={sliderRef}
      >
        {slides}
      </Slider>
    </div>
  );
};

Carousel3D.propTypes = {
  slides: T.array.isRequired,
  config: T.object,
  className: T.string,
  focusOnLoad: T.bool,
};

export default Carousel3D;
