import React from 'react';
import T from 'prop-types';

const Slide = ({
  data,
}) => (
  <div className="hero-card-slide">
    <header className="hero-card-slide__header">
      <div className="hero-card-slide__header-icon" dangerouslySetInnerHTML={{ __html: data.header.header_icon_node }} />
      <div className="hero-card-slide__header-text">
        <div className="hero-card-slide__header-headline" dangerouslySetInnerHTML={{ __html: data.header.headline_node }} />
        <div className="hero-card-slide__header-subheadline" dangerouslySetInnerHTML={{ __html: data.header.subheadline_node }} />
      </div>
    </header>

    <div className="hero-card-slide__body">
      <div className="hero-card-slide__body-picture" dangerouslySetInnerHTML={{ __html: data.body.picture_node }} />
      <div className="hero-card-slide__body-picture--mobile" dangerouslySetInnerHTML={{ __html: data.body.picture_mobile_node }} />
    </div>

    <footer
      className="hero-card-slide__footer"
      dangerouslySetInnerHTML={{ __html: data.footer.link_node }}
    />
  </div>
);

Slide.propTypes = {
  data: T.object.isRequired,
};

export default Slide;
