import React from 'react';
import T from 'prop-types';
import { createRoot } from 'react-dom/client';

import Slide from './Slide';
import Carousel3D from '../../../../components/Carousel3D';

const config = ({
  autoplay: true,
  autoplaySpeed: 7000,
  arrows: false,
  dots: true,
  infinite: true,
  variableWidth: false,
  centerMode: false,
  fade: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  pauseOnDotsHover: false,
  responsive: null,
});

const HeroSimpleCarousel = ({ data }) => (
  <Carousel3D
    className="hero-simple-carousel"
    config={config}
    slides={data.map(slide => (
      <Slide
        key={slide.id}
        data={slide}
      />
    ))}
  />
);
HeroSimpleCarousel.propTypes = {
  data: T.array,
};

export default HeroSimpleCarousel;

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('home-hero-carousel-root');

  if (rootNode) {
    const slides = [];

    rootNode.querySelectorAll('.hero-card-slide').forEach((item) => {
      slides.push(JSON.parse(item.dataset.slide));
    });

    const root = createRoot(rootNode);
    root.render(<HeroSimpleCarousel data={slides} />);
  }
});

// Slide fields
// id
// header: {
//   header_icon_node
//   headline_node
//   subheadline_node
// }
// body: {
//   picture_node
//   picture_mobile_node
// }
// footer: {
//   link_node
// }
